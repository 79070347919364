import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Carbon is IBM’s open-source design system for digital products and experiences. With the IBM Design Language as its foundation, the system consists of working code, design tools and resources, human interface guidelines, and a vibrant community of contributors.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Introduction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Guiding principles</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Governance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Certificate of Originality</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`As IBM’s official design system, Carbon serves a wide range of designers and developers building digital products and experiences. It is maintained by a core team of designers, developers, and writers based in the Austin, Texas IBM Design Studio.`}</p>
    <p>{`The goals of the design system include improving UI consistency and quality, making the design and development process more efficient and focused, establishing a shared vocabulary between designer and developer, and providing clear, discoverable guidance around design and development best practices.`}</p>
    <h2 {...{
      "id": "guiding-principles"
    }}>{`Guiding principles`}</h2>
    <p><strong parentName="p">{`Carbon is open.`}</strong>{` The design system is a distributed effort, guided by the principles of the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Open-source-software_movement"
      }}>{`open-source movement`}</a>{`. Carbon’s users are also its makers, and everyone is encouraged to contribute.`}</p>
    <p><strong parentName="p">{`Carbon is inclusive.`}</strong>{` It’s designed and built to be accessible to all, regardless of ability or situation.`}</p>
    <p><strong parentName="p">{`Carbon is modular and flexible.`}</strong>{` The system’s modularity ensures maximum flexibility in execution. Its components are designed to work seamlessly with each other, in whichever combination suits the needs of the user.`}</p>
    <p><strong parentName="p">{`Carbon puts the user first.`}</strong>{` Using rigorous research into users’ needs and desires, Carbon is laser-focused on real people.`}</p>
    <p><strong parentName="p">{`Carbon builds consistency.`}</strong>{` Based on the IBM Design Language, every element and component of Carbon was designed from the ground up to work elegantly together to ensure consistent, cohesive user experiences.`}</p>
    <h2 {...{
      "id": "governance"
    }}>{`Governance`}</h2>
    <p>{`The Carbon team encourages adoption through guidance and training, community development, maintenance, and support. The system is primarily maintained by a core team and governed by an executive board of design advisors.`}</p>
    <h3 {...{
      "id": "adoption"
    }}>{`Adoption`}</h3>
    <p><strong parentName="p">{`We conduct training classes and offer certifications.`}</strong>{` These cover introductions to Carbon and participating in this community. Each program is piloted internally and available upon release externally. We teach at conferences, bootcamp labs, and wherever else we’re needed.`}</p>
    <p><strong parentName="p">{`We engage the community.`}</strong>{` We strive to be one of the world’s best design systems and we’re always `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/issues/new?assignees=&labels=feedback&template=feedback.md&title="
      }}>{`open to feedback`}</a>{`. We communicate with you about Carbon’s state and roadmap through GitHub support, blog posts, and Twitter.`}</p>
    <p><strong parentName="p">{`Contributed components and patterns include a list of maintainers.`}</strong>{` The Carbon team triages and supports maintenance requests that do not have a maintainer. Unmaintained patterns will be demoted to Experimental if the Carbon team decides not to maintain them.`}</p>
    <p><strong parentName="p">{`We provide support for users of the design system.`}</strong>{` The Carbon team engages with users primarily through GitHub. A member of the Carbon team responds to all issues and pull requests.`}</p>
    <h3 {...{
      "id": "assets"
    }}>{`Assets`}</h3>
    <p><strong parentName="p">{`We maintain a set of Sketch libraries containing Carbon components and patterns.`}</strong>{` There is a funnel of contribution for rigorous Sketch symbols before they are made available in Sketch libraries.`}</p>
    <p><strong parentName="p">{`Carbon supports multiple code implementations.`}</strong>{` These reflect the production-level design libraries. The frameworks are listed below with their primary maintainers:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon"
        }}>{`React`}</a>{`: Carbon Core team`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon"
        }}>{`CSS/Vanilla`}</a>{`: Carbon Core team`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://angular.carbondesignsystem.com/"
        }}>{`Angular`}</a>{`: Community maintained`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-components-vue"
        }}>{`Vue`}</a>{`: Community maintained`}</li>
    </ul>
    <p><strong parentName="p">{`Design patterns are harvested from products built with Carbon.`}</strong>{` These become part of the design system. Teams can can use these well-defined patterns in their work and contribute patterns back to the system.`}</p>
    <h3 {...{
      "id": "contribution-acceptance"
    }}>{`Contribution acceptance`}</h3>
    <p>{`We bring experimental components and patterns into the design library as quickly as possible with little barrier to entry.`}</p>
    <p><strong parentName="p">{`Experimental status means duplicate patterns may exist.`}</strong>{` Accompanying guidance may be minimal. Maintainers are not yet identified or committed. Inclusion in the shared library raises awareness and acts as a live audit so people can benefit from the collaboration.`}</p>
    <p><strong parentName="p">{`Beta status means there are few or no duplicate patterns.`}</strong>{` Guidance on the site is reasonably complete and useful. Maintainers are identified and committed to ongoing support. The intention is to go to production with only minor changes based on user feedback.`}</p>
    <p><strong parentName="p">{`Production status there are no duplicate patterns.`}</strong>{` These offer high-quality guidance. Design symbols and code components are available. Maintainers know to monitor support channels for their patterns.`}</p>
    <h2 {...{
      "id": "certificate-of-originality"
    }}>{`Certificate of Originality`}</h2>
    <p>{`This information is only relevant for IBM teams building with Carbon.`}</p>
    <h3 {...{
      "id": "carbon-v10x"
    }}>{`Carbon v10.x`}</h3>
    <ul>
      <li parentName="ul">{`COO record ID: 95607540`}</li>
      <li parentName="ul">{`COO reference number: COO20190204`}</li>
      <li parentName="ul">{`Name of IBM material (with version number): IBM Design System `}{`[Version 10, Release n/a, Modification n/a]`}</li>
      <li parentName="ul">{`Last release date: March 31, 2019`}</li>
      <li parentName="ul">{`Name of Product/Program manager or author of material: Daniel Kuehn/Austin/IBM`}</li>
    </ul>
    <h3 {...{
      "id": "carbon-v9x"
    }}>{`Carbon v9.x`}</h3>
    <ul>
      <li parentName="ul">{`COO number of IBM material (if any): AUS720180239`}</li>
      <li parentName="ul">{`Name of IBM material (with version number): IBM Design System `}{`[Version 9, Release n/a, Modification n/a]`}</li>
      <li parentName="ul">{`Last release date: June 4, 2018`}</li>
      <li parentName="ul">{`Name of Product/Program manager or author of material: DanielKuehn/Austin/IBM`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      